$fa-font-path: '~font-awesome/fonts';

.btn {
  margin: 3px !important;
}

.rental-places-box {
  padding: 1%;
  -webkit-box-shadow: 6px 7px 48px 1px rgba(0,0,0,0.47);
  -moz-box-shadow: 6px 7px 48px 1px rgba(0,0,0,0.47);
  box-shadow: 6px 7px 48px 1px rgba(0,0,0,0.47);
}

.bottom-right {
  position: absolute;
  bottom: 0;
  right: 0;
  color: white;
}

#caravan-logo {
  -ms-transform: rotate(-15deg); /* IE 9 */
  -webkit-transform: rotate(-15deg); /* Chrome, Safari, Opera */
  transform: rotate(-15deg);
}

.qq-upload-button {
  width: 100% !important;
  background-color: rgb(0, 200, 81) !important; /* Make sure the MDB styling is used! */
}

.left-rotated {
  -ms-transform: rotate(-90deg); /* IE 9 */
  -webkit-transform: rotate(-90deg); /* Chrome, Safari, Opera */
  transform: rotate(-90deg);
  position: fixed;
  left: 0px;
  top: 50%;
}

.right-rotated {
  -ms-transform: rotate(90deg); /* IE 9 */
  -webkit-transform: rotate(90deg); /* Chrome, Safari, Opera */
  transform: rotate(90deg);
  position: fixed;
  right: 10px;
  top: 50%;
}

.btn {
  margin: 3px !important;
}

.rental-places-box {
  padding: 1%;
  -webkit-box-shadow: 6px 7px 48px 1px rgba(0,0,0,0.47);
  -moz-box-shadow: 6px 7px 48px 1px rgba(0,0,0,0.47);
  box-shadow: 6px 7px 48px 1px rgba(0,0,0,0.47);
}

.bottom-right {
  position: absolute;
  bottom: 0;
  right: 0;
  color: white;
}

#caravan-logo {
  -ms-transform: rotate(-15deg); /* IE 9 */
  -webkit-transform: rotate(-15deg); /* Chrome, Safari, Opera */
  transform: rotate(-15deg);
}

.qq-upload-button {
  width: 100% !important;
  background-color: rgb(0, 200, 81) !important; /* Make sure the MDB styling is used! */
}

.left-rotated {
  -ms-transform: rotate(-90deg); /* IE 9 */
  -webkit-transform: rotate(-90deg); /* Chrome, Safari, Opera */
  transform: rotate(-90deg);
  position: fixed;
  left: 0px;
  top: 50%;
}

.right-rotated {
  -ms-transform: rotate(90deg); /* IE 9 */
  -webkit-transform: rotate(90deg); /* Chrome, Safari, Opera */
  transform: rotate(90deg);
  position: fixed;
  right: 10px;
  top: 50%;
}

@import "~tether/dist/css/tether.min.css";
@import '~bootstrap/scss/bootstrap';
@import "~mdbootstrap/css/mdb.min.css";
@import "~font-awesome/scss/font-awesome";
@import "~fine-uploader/fine-uploader/fine-uploader.min.css";
@import "~fine-uploader/fine-uploader/fine-uploader-gallery.min.css";
@import "~bootstrap-datepicker/dist/css/bootstrap-datepicker.standalone.min.css";
@import "~datatables.net-bs4/css/dataTables.bootstrap4.css";
@import "~ekko-lightbox/dist/ekko-lightbox.css";
